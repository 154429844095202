export const FR = {
  translation: {
    home: {
      title: `
        <span class="c-pink">Level</span> 
        <span class="c-blue">Up,</span>
        `,
      baseline: 'construis ton futur, même sans le bac !',
      txt1: 'Level Up est un programme gratuit, ouvert à tout le monde pour se former et trouver un emploi dans le digital.',
      txt2: "Pas besoin de diplôme pour y accéder. Tu n'y connais rien à l'informatique ? C'est pas grave. Level Up est conçu pour t'accompagner quels que soient ton profil et ton expérience. Seule ta motivation compte.",
      txt3: 'Lance-toi !',
    },
    progress: {
      labelTop1: 'Ta première<br> formation',
      labelTop2: ' Tu choisis<br /> ton chemin',
      labelTop3: 'Tu apprends<br/> ton nouveau métier',
      labelTop4: ' Tu es prêt<br />à te lancer',
      toi: 'Toi',
      nous: 'nous',
      circle1: 'Hiver<br>2024',
      circle2: 'PRINTEMPS<br>2024',
      circle3: 'ÉTÉ<br>2024',
      circle4: 'AUTOMNE<br>2024',
      labelBottom1:
        'On prépare la plateforme, on t’accompagne dans tes premiers pas',
      labelBottom2: `<span class="progress_nous_txt"><span class="c-blue t-bold">Lancement de la plateforme</span><br>On te guide et on t’aide à dessiner ton parcours</span>`,
      labelBottom3:
        'On te donne accès à un programme qui fera de toi un spécialiste',
      labelBottom4:
        'On organise les meet-ups et on prépare ensemble ton entretien',
    },
    steps: {
      title: "<strong>Level Up</strong>, c'est quoi ?",
      allSteps: [
        {
          title: 'Des conseils',
          chap: 'Tu ne sais pas par où commencer ?',
          txt: `Le monde de l'informatique
                est un labyrinthe où il est
                facile de se perdre.
                Level Up te propose <strong class='t-bold'>un bilan</strong>
                pour t'aider à trouver ton
                chemin.`,
          url: '/imgs/icon_conseils.svg',
        },
        {
          title: 'Des formations',
          chap: 'Avec toi à chaque étape',
          txt: `On ne naît pas expert, avec Level Up, on le devient. Les formations sont accessibles à tout moment, depuis un ordinateur ou un smartphone. Nos coachs et préparateurs professionnels t'accompagnent de <strong class='t-bold'>A&nbsp;à&nbsp;Z</strong>.`,
          url: '/imgs/icon_formations.svg',
        },
        {
          title: 'Un métier',
          chap: "Des offres d'emploi pour toi",
          txt: `<strong class='t-bold'>Tu postules directement</strong> sur Level Up pour trouver une entreprise qui a besoin des
                compétences que tu as acquises.
                C'est gagnant-gagnant.`,
          url: '/imgs/icon_metier.svg',
        },
      ],
    },
    try: {
      tries: [
        {
          title: `Introduction à la Cybersécurité`,
          txt: `Un cours de 6 heures* qui t’apprendra les bases du monde de la Cybersécurité. Ton premier pas vers un poste dans la Cyber !`,
          link: 'https://skillsforall.com/course/introduction-to-cybersecurity?courseLang=fr-FR&instance_id=64dc68bb-5a99-4ca9-b39b-3b7ce8febb2a',
        },
        {
          title: `Introduction à l’IoT et la Transformation Digitale`,
          txt: `Un cours de 6 heures* pour comprendre l’univers du numérique, et comment il transforme le monde professionnel et notre vie de tous les jours.`,
          link: 'https://skillsforall.com/course/introduction-iot?courseLang=fr-FR&instance_id=5a70cd5c-576c-4d66-887a-6548d28eac1a',
        }
      ],

      title: 'Envie d’essayer ?',
      btn: 'Commencer',
      baseline: `Découvre dès maintenant ce qui t’attend avec ces trois premiers cours :`,

      credits: '* La durée peut varier d’un utilisateur à un autre.',
    },
    try2: {
      tries: [{
          title: `Notions de base sur les réseaux`,
          txt: `Ce cours couvre les bases de la mise en réseau et des dispositifs médias et protocoles de réseau.`,
          link: 'https://skillsforall.com/fr/course/networking-basics?courseLang=fr-FR',
        },
      ],

      btn: 'Commencer',
      baseline: `Pour recevoir ton invitation à rejoindre la plateforme, tes coachs et ton futur emploi, complète les 4 premiers chapitres de ce troisième cours :`
    },
    coursesv2:{
      title: `Comment ça<br/> marche`,
      courses: [
        {
          number: '1',
          image: '/imgs/coursesV2/courses1.jpg',
          alt: '',
          text: `Clique sur le cours de ton choix : <a class="c-blue" target="_blank" href="https://skillsforall.com/course/introduction-to-cybersecurity?courseLang=fr-FR&instance_id=64dc68bb-5a99-4ca9-b39b-3b7ce8febb2a">Introduction à la Cybersécurité</a>, <a class="c-blue" target="_blank" href="https://skillsforall.com/course/introduction-iot?courseLang=fr-FR&instance_id=5a70cd5c-576c-4d66-887a-6548d28eac1a">Introduction à l’IoT et la Transformation Digitale</a> ou <a class="c-blue" target="_blank" href="https://skillsforall.com/fr/course/networking-basics?courseLang=fr-FR">Networking Basics</a>.`
        },
        {
          number: '2',
          image: '/imgs/coursesV2/courses2.jpg',
          alt: '',
          text: `Complète un cours et demi. On t’envoie une invitation pour te connecter à Level Up.`
        },
        {
          number: '3',
          image: '/imgs/coursesV2/courses3.jpg',
          alt: '',
          text: `Crée ton compte sur la plateforme. On valide ton profil et <span class="c-blue">Level Up est à toi !</span>`
        },
      
      ]

    },
    partners: {
      title: 'En collaboration avec',
    },
    header: {
      links: [
        {title: 'A PROPOS'},
        {title: 'TÉMOIGNAGES'},
        {title: 'PARCOURS'},
        {title: 'PREMIERS COURS'},
        {title: 'SE CONNECTER'}
      ]
    },
    footer: {
      links: [
        // {
        //   url: '/privacy-policy',
        //   title: 'Politique de confidentialité',
        // },
        {
          url: '/cookies',
          title: 'Cookies',
        },
      ],

      credits: '© 2024 Level Up',
      contact: 'Contactez-nous',
    },

    cookies: {
      BannerTxt : `Ce site web utilise des cookies analytiques anonymes, y compris des cookies de tiers, pour collecter des informations sur la manière dont les utilisateurs se servent du site. Utilisez le bouton "Ok" pour donner votre accord. Pour continuer sans accepter, appuyez sur le bouton "Non"`,
      back: 'Retour',
      title: 'Cookies',
      txt: `<h2>Politique en matière de cookies</h2>

<p>Conformément à l'article 13 du règlement (UE) 2016/679 relatif au traitement des données à caractère personnel (ci-après " RGPD "), Cisco Systems Italy S.r.l. (ci-après, " CISCO ") vous informe des informations suivantes concernant les cookies installés sur le  www.levelup93.fr. (ci-après " Site "). </p>

<h3>Responsable de traitement</h3> 
<p>
Le Responsable de traitement est Cisco Systems Italy S.r.l. - dont le siège social est situé Via Vittor Pisani, 20 - 20124 Milan (Italie) (ci-après le " Responsable de traitement").  
</p>
<h3>
Que sont les cookies ? 
</h3>
<p>
Les cookies sont des chaînes de texte créées par un serveur et stockées sur le disque dur de l'ordinateur ou sur tout appareil utilisé par l'utilisateur pour accéder à Internet (smartphones, tablettes), puis retransmises lors des accès ultérieurs de l'utilisateur à Internet. 
</p>
Les cookies permettent de recueillir des informations sur la navigation des utilisateurs sur le site. 
<p>
Les cookies peuvent être stockés de manière permanente sur votre ordinateur et avoir une durée de vie variable (appelés cookies persistants), mais ils peuvent aussi disparaître à la fermeture du navigateur ou avoir une durée limitée (cookies de session). 
</p><p>
Les cookies peuvent provenir du site que vous visitez (cookies internes) ou d'autres sites (cookies de tiers). 
</p>
 
<h3>
 Cookies utilisés et objectifs
</h3>

<p>
Les cookies de navigation et de session ne sont pas utilisés sur ce Site 
</p><p>
Cookies (de tierces parties) analytiques anonymes 
</p><p>
Ces cookies de tiers anonymisés, provenant notamment de la plateforme Google Analytics, sont utilisés pour collecter des informations sur l'utilisation du site (nombre de visiteurs, pages visitées, temps passé sur le site, etc.) 
</p>
<div class="table_wrapper" >
<div class="table_inner">

<table class="table">
<thead>

<tr>
<th>
Cookie 
</th>

<th>
 Nom 
</th>

<th>
Finalité 
</th>

<th>
Type 
</th>

<th>
Durée 
</th>

<th>
Consentement 
</th>

<th>
 Informations complémentaires 
</th>

</tr>
</thead>
<tr>

<td>
Google 
</td>

<td>
_ga 
</td>

<td>
Utilisé pour distinguer les utilisateurs - requis par Google Analytics tiers  
</td>

<td>
3ème partie 
</td>

<td>
Persistant (2 ans) 
</td>

<td>
Non requis 
</td>

<td rowspan="2">
La politique de confidentialité et la façon d'utiliser les cookies Google sont disponibles dans les liens suivants: 

<a href="https://www.google.com/intl/it/policies/privacy/" target="_blank">https://www.google.com/intl/it/policies/privacy/ </a>

<a target="_blank" href='https://www.google.com/intl/it/policies/technologies/'>https://www.google.com/intl/it/policies/technologies/ </a>

Vous pouvez télécharger un plug-in Navigateur pour désactiver définitivement Google Analytics au lien suivant: 

<a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=it </a>
</td>

</tr>


<tr>
<td>

</td>

<td>
_gat 
</td>

<td>
Permet la limite d'accélération au service Google Analytics. 
</td>

<td>

</td>
<td>
Temporaire (10 minutes)  
</td>

<td>

</td>

</tr>

</table>
</div>
</div>
<h3>
Gérer les préférences en matière de cookies 
</h3><p>
Lorsque vous accédez à une page du site, une bannière contenant de brèves informations est affichée. 
</p><p>
Vous pouvez vous opposer à l'enregistrement de cookies de tiers sur votre disque dur à travers le lien indiqué dans le tableau ci-dessus, tout en bénéficiant de l'accès et de l'utilisation des pages web, tandis que vous pouvez vous opposer à la première partie de l'enregistrement des cookies sur votre disque dur en configurant votre navigateur pour désactiver les cookies. Cependant, après avoir fait cela, vous ne pourrez pas accéder aux pages web. 
</p><p>
Voici les moyens offerts par les principaux navigateurs : 
</p>

<ul>
<li>
Internet Explorer ( <a href="https://windows.microsoft.com/fr-fr/windows-vista/block-or-allow-cookies" target="_blank" >https://windows.microsoft.com/fr-fr/windows-vista/block-or-allow-cookies</a> ) 
</li><li>

Chrome ( <a href="https://support.google.com/chrome/answer/95647?hl=fr&p=cpn_cookies" target="_blank"> https://support.google.com/chrome/answer/95647?hl=fr&p=cpn_cookies </a> ) 
</li><li>
Firefox ( <a href="https://support.mozilla.org/fr/kb/sites-dissent-cookies-bloques-les-debloquer" target="_blank"> https://support.mozilla.org/fr/kb/sites-dissent-cookies-bloques-les-debloquer</a> ) 
</li><li>
Opéra ( <a href="http://help.opera.com/Windows/10.00/fr/cookies.html" target="_blank">http://help.opera.com/Windows/10.00/fr/cookies.html</a> ) 
</li><li>
Safari ( <a href="https://support.apple.com/kb/PH19214?viewlocale=fr_IT&locale=fr_US">https://support.apple.com/kb/PH19214?viewlocale=fr_IT&locale=fr_US</a> ) 
</li></ul>


<p>
Communication et diffusion des données 
</p><p>
Ces données peuvent également être traitées par des personnes de confiance qui effectuent des tâches techniques et organisationnelles pour le compte du Responsable de traitement ou de son sous-traitant.  
</p><p>
Les données recueillies à l'aide des cookies ne sont pas transmises à des tiers et ne sont pas diffusées. 
</p>

<h2>
Droits de la personne concernée
</h2>

<p>
En tant que personne concernée, conformément aux articles 15 à 22 du RGPD, le cas échéant, vous avez le droit de : 
</p>

<ul>
<li>
a) Accéder à vos données et en demander une copie ; 
</li><li>
b) Demander une rectification ; 
</li><li>
c) Demander l'effacement ; 
</li><li>
d) Obtenir la limitation du traitement ; 
</li><li>
e) S'opposer au traitement ; 
</li><li>
f) Recevoir dans un format structuré, couramment utilisé et lisible par machine et transmettre ces données sans entrave à un autre responsable du traitement des données, lorsque cela est techniquement possible. 
</li>
</ul>
<p>
Veuillez noter que vos droits ci-dessus sont limités par la loi et que nous devons les respecter, éventuellement sous certaines conditions. 
</p><p>
Si vous souhaitez exercer l'un des droits énumérés ci-dessus, veuillez envoyer un e-mail à l'adresse suivante : levelup93@logotel.it  
</p><p>
Sans préjudice de tout autre recours administratif ou judiciaire, pour exercer votre droit de déposer une plainte (art. 77 GDPR), vous pouvez également contacter la CNIL (Commission Nationale de l’Informatique et des Libertés). 
</p>
      `,
      actionTxt: ` <p>
      Ce site utilise des cookies pour améliorer l'expérience
      utilisateur. Voulez vous etres tracké ?
    </p>`,
    },
    privacyPolicy: {
      back: 'Retour',
      title: 'Privacy policy1',
      txt: ` <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
      in nesciunt eos, delectus provident ratione maiores accusamus
      suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
      dignissimos perspiciatis.
    </p> `,
    },

    JobPage: [
      {
        title: 'Cybersécurité',
        value: 'cybersecurity',
        tags: ['Présentiel'],

        img: {
          src: '/imgs/jobs/header_cybersecurite.png',
          alt: 'Cybersécurité',
        },

        infos: {
          title: 'En quoi ça consiste ?',
          txt: `<p class="jobIntro_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna.</p><p class="jobIntro_txt">Sed id rutrum augue. Mauris iaculis augue in sem dictum laoreet eget in erat. Pellentesque vitae rutrum ligula, eu venenatis erat. Cras vitae posuere dui, in tincidunt quam. Fusce ornare metus eget dui auctor consequat. Cras pellentesque convallis scelerisque. Nunc sed metus nisi. Integer malesuada fringilla interdum. Aenean vel ipsum nec est convallis iaculis.</p><p class="jobIntro_txt"><strong>Ut ut augue interdum, fermentum mi sed, gravida tortor. Aenean consectetur blandit purus vel aliquet. Integer accumsan dignissim porta. Proin sed neque et erat gravida pulvinar.</strong></p>`,
        },

        competences: {
          title: 'Quelles compétences ?',

          txts: [
            {
              title: 'Hardskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
            {
              title: 'Softskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
          ],
        },

        remuneration: {
          title: 'Quelle rémunération ?',
          max: {
            value: '50 000 €',
            txt: 'maximum',
          },
          min: {
            value: '25 000 €',
            txt: 'minimum',
          },
        },
      },
      {
        title: 'Networking',
        value: 'networking',
        tags: ['Présentiel'],
        img: {
          src: '/imgs/jobs/header_networking.png',
          alt: 'Networking',
        },

        infos: {
          title: 'En quoi ça consiste ?',
          txt: `<p class="jobIntro_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna.</p><p class="jobIntro_txt">Sed id rutrum augue. Mauris iaculis augue in sem dictum laoreet eget in erat. Pellentesque vitae rutrum ligula, eu venenatis erat. Cras vitae posuere dui, in tincidunt quam. Fusce ornare metus eget dui auctor consequat. Cras pellentesque convallis scelerisque. Nunc sed metus nisi. Integer malesuada fringilla interdum. Aenean vel ipsum nec est convallis iaculis.</p><p class="jobIntro_txt"><strong>Ut ut augue interdum, fermentum mi sed, gravida tortor. Aenean consectetur blandit purus vel aliquet. Integer accumsan dignissim porta. Proin sed neque et erat gravida pulvinar.</strong></p>`,
        },

        competences: {
          title: 'Quelles compétences ?',

          txts: [
            {
              title: 'Cybersécurity',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
            {
              title: 'Softskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
          ],
        },

        remuneration: {
          title: 'Quelle rémunération ?',
          max: {
            value: '50 000 €',
            txt: 'maximum',
          },
          min: {
            value: '25 000 €',
            txt: 'minimum',
          },
        },
      },

      {
        title: 'Datas',
        value: 'data',
        tags: ['Présentiel'],
        img: {
          src: '/imgs/jobs/header_data.png',
          alt: 'Datas',
        },

        infos: {
          title: 'En quoi ça consiste ?',
          txt: `<p class="jobIntro_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna.</p><p class="jobIntro_txt">Sed id rutrum augue. Mauris iaculis augue in sem dictum laoreet eget in erat. Pellentesque vitae rutrum ligula, eu venenatis erat. Cras vitae posuere dui, in tincidunt quam. Fusce ornare metus eget dui auctor consequat. Cras pellentesque convallis scelerisque. Nunc sed metus nisi. Integer malesuada fringilla interdum. Aenean vel ipsum nec est convallis iaculis.</p><p class="jobIntro_txt"><strong>Ut ut augue interdum, fermentum mi sed, gravida tortor. Aenean consectetur blandit purus vel aliquet. Integer accumsan dignissim porta. Proin sed neque et erat gravida pulvinar.</strong></p>`,
        },

        competences: {
          title: 'Quelles compétences ?',

          txts: [
            {
              title: 'Cybersécurity',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
            {
              title: 'Softskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
          ],
        },

        remuneration: {
          title: 'Quelle rémunération ?',
          max: {
            value: '50 000 €',
            txt: 'maximum',
          },
          min: {
            value: '25 000 €',
            txt: 'minimum',
          },
        },
      },
    ],
    tags: {
      Présentiel: 'Présentiel',
      Remote: 'Remote',
      Hybride: 'Hybride',
    },
    partenersPage: {
      title: `Nos <span className="c-pink">partenaires</span>`,
      list: [
        {
          name: 'Cisco',
          link: {
            txt: 'Visiter Networking Academy >',
            path: 'https://www.netacad.com/fr',
          },
          img: {
            alt: 'Cisco',
            path: '/imgs/cisco_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },

        {
          name: 'Plaine',
          link: {
            txt: 'Visiter le site de la Plaine Commune >',
            path: 'https://plainecommune.fr/',
          },
          img: {
            alt: 'Plaine',
            path: '/imgs/plaine_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },

        {
          name: 'Mozaïk',
          link: {
            txt: 'Visiter le site de la Fondation Mozaïk >',
            path: 'https://www.fondation-mozaik.org/',
          },
          img: {
            alt: 'Mozaïk',
            path: '/imgs/mozaik_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },
        {
          name: 'Randstad',
          link: {
            txt: 'Visiter le site de Randstad >',
            path: 'https://www.randstad.fr/',
          },
          img: {
            alt: 'Randstad',
            path: '/imgs/randstad_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Le Groupe Randstad a vu le jour aux Pays-Bas en 1960 et s'est depuis affirmé en tant que leader mondial dans le domaine des ressources humaines, intervenant à toutes les étapes de la vie professionnelle : du recrutement à l'intégration, du management à la formation, et en passant par la mobilité.​
      </p>`,
        },
        {
          name: 'Logotel',
          link: {
            txt: 'Visiter le site de Logotel >',
            path: 'https://www.logotel.it/',
          },
          img: {
            alt: 'Logotel',
            path: '/imgs/logotel_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },
      ],
    },
    who: {
      title: 'Pour qui?',

      presentation: {
        title: 'Présentation',
        subtitle: 'Armand',
        txt: `Armand Leblois, responsable européen de la Cisco Networking Academy, te présente Level Up. Toi aussi, rejoins dès maintenant les 400 000 étudiants qui utilisent déjà ce programme en Europe pour se former aux métiers du numérique !`,
          video: {
            name: 'Présentation',
            path: '/videos/ARMAND/video.mp4',
            srt: {
              en: '/videos/ARMAND/srt/en.vtt',
              fr: '/videos/ARMAND/srt/fr.vtt',
            },
            poster: '/videos/ARMAND/poster.png',
            details: `Armand Leblois, responsable européen de la Cisco Networking Academy, te présente Level Up. Toi aussi, rejoins dès maintenant les 400 000 étudiants qui utilisent déjà ce programme en Europe pour se former aux métiers du numérique ! `,
          },
      },

      portrait: {
        title: 'Portrait',
        subtitle: 'Arnaud',
        txt: `Arnaud Burlion est ingénieur réseaux et formateur à la Cisco Networking Academy… tout ça sans avoir passé le bac. Il te raconte comment la Cisco Networking Academy lui a permis de prendre un nouveau tournant dans sa carrière.`,
          video: {
            name: 'Arnaud',
            path: '/videos/ARNAUD/video.mp4',
            srt: {
              en: '/videos/ARNAUD/srt/en.vtt',
              fr: '/videos/ARNAUD/srt/fr.vtt',
            },
            poster: '/videos/ARNAUD/poster.png',
            details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh.`,
          },
      },
    },

    temoignages: {
      title: 'Témoignages',

      videos: [
       
        {
          name: 'Mohamed-Amine',
          path: '/videos/MOHAMED-AMINE/video.mp4',
          srt: {
            en: '/videos/MOHAMED-AMINE/srt/en.vtt',
            fr: '/videos/MOHAMED-AMINE/srt/fr.vtt',
          },
          poster: '/videos/MOHAMED-AMINE/poster.jpg',
          details: `C’est une plateforme qui permet l’accès aux cours en totale autonomie, tout en offrant un accompagnement.`,
        },
        {
          name: 'Choukry',
          path: '/videos/CHOUKRY/video.mp4',
          srt: {
            en: '/videos/CHOUKRY/srt/en.vtt',
            fr: '/videos/CHOUKRY/srt/fr.vtt',
          },
          poster: '/videos/CHOUKRY/poster.jpg',
          details: `Pour moi, la Cisco Networking Academy est un support sur lequel je peux m’appuyer, m’approprier le contenu et progresser à mon rythme.`,
        },

        {
          name: 'Fabien',
          poster: '/videos/FABIEN/poster.jpg',
          path: '/videos/FABIEN/video.mp4',
          srt: {
            en: '/videos/FABIEN/srt/en.vtt',
            fr: '/videos/FABIEN/srt/fr.vtt',
          },
          details: `Vous pouvez réussir si vous avez un rêve, même si vous êtes en situation de handicap.`,
        },

        {
          name: 'Morgane',
          path: '/videos/MORGANE/video.mp4',
          srt: {
            en: '/videos/MORGANE/srt/en.vtt',
            fr: '/videos/MORGANE/srt/fr.vtt',
          },
          poster: '/videos/MORGANE/poster.jpg',
          details: `Je me sentais coincée dans un métier qui ne me plaisait plus vraiment. C’est là que j’ai découvert la Cisco Networking Academy.`,
        },

        {
          name: 'Charlotte',
          path: '/videos/CHARLOTTE/video.mp4',
          srt: {
            en: '/videos/CHARLOTTE/srt/en.vtt',
            fr: '/videos/CHARLOTTE/srt/fr.vtt',
          },
          poster: '/videos/CHARLOTTE/poster.jpg',
          details: `Beaucoup de femmes pensent encore, à tort, que les métiers de l’informatique sont inaccessibles pour elles.`,
        },
      ],
    },
    jobC: {
      title: 'Quels métiers ?',
      list: [
        {
          value: 'cybersecurity',
          title: 'Cybersecurity',
          img: '/imgs/jobs/header_cybersecurite.png',
          tags: ['Présentiel', 'Remote', 'Hybride'],

          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Pellentesque blandit nibh eget ipsum pretium tincidunt nec vitae tortor.
        <br/><br/>
        Aliquam nisl turpis, varius ac lacus vitae, efficitur efficitur erat.
        <br/>   <br/>         
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. `,
          name: 'Cybersécurité',
        },
        {
          value: 'networking',
          title: 'Networking',
          img: '/imgs/jobs/header_networking.png',
          tags: ['Présentiel'],
          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Pellentesque blandit nibh eget ipsum pretium tincidunt nec vitae tortor.
          <br/><br/>
        Aliquam nisl turpis, varius ac lacus vitae, efficitur efficitur erat.
        <br/><br/>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. `,
          name: 'Networking',
        },
        {
          value: 'data',
          title: 'Data',
          img: '/imgs/jobs/header_data.png',
          tags: ['Présentiel'],
          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Pellentesque blandit nibh eget ipsum pretium tincidunt nec vitae tortor.
          <br/><br/>
        Aliquam nisl turpis, varius ac lacus vitae, efficitur efficitur erat.
        <br/><br/>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. `,
          name: 'Datas',
        },
      ],
    },
    more: 'En savoir plus',
    back: 'Retour',
    yes : 'Oui',
    no: 'Non',
    ok: 'Ok',
  },
}
